import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const useActiveProgram = (incentive) => {
  const programs = useSelector((state) => state?.users?.programs || [], shallowEqual);
  const programsReady = useSelector((state) => state?.users?.programsReady, shallowEqual);
  const activeProgram = useMemo(
    () => (programsReady
      ? programs.find((p) => p.incentiveId === incentive._id)
      : undefined
    ),
    [programsReady, programs, incentive._id],
  );

  return activeProgram;
};

export default useActiveProgram;
