import React, {
  useCallback, useRef,
} from 'react';
import { useRouter } from 'next/router';
import { Box } from '@tmap/mmm-style-guide/src/Box';
import { Hyperlink } from '@tmap/mmm-style-guide/src/Hyperlink';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import stopEvent from '@tmap/mmm-style-guide/src/util/stopEvent';

import { LeadStatus } from '@tmap/mmm-core/leads/status';
import useActiveProgram from '../../hooks/useActiveProgram';

const IncompleteTasksBox = styled(Box, {
  shouldForwardProp: (p) => p !== 'hasTasks',
})(({ theme, hasTasks }) => ({
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.grey[100],
  padding: theme.spacing(1, 2.5, 1, 1.5),
  borderRadius: '4px',
  minHeight: '36.5px',
  '&::before': {
    content: '""',
    width: '8px',
    height: '8px',
    flexShrink: 0,
    marginRight: theme.spacing(1),
    borderRadius: '8px',
    background: hasTasks ? theme.palette.error.main : theme.palette.success.main,
  },
}));

function IncompleteTasksCTA({ incentive, ...otherProps }) {
  const postUGC = useRef();
  const router = useRouter();

  const program = useActiveProgram(incentive);

  const ugcUrl = `/moving-living/${encodeURIComponent(incentive?.communities?.[0]?.slug?.current)}#post`;

  const handlePostClick = useCallback((event) => {
    stopEvent(event);
    router.push(ugcUrl);
  }, [ugcUrl, router]);

  const isMoved = [LeadStatus.MOVED, LeadStatus.INCENTIVE_DELIVERED]
    .includes(program?.status?.[0].status);

  return (
    (program?.incompleteTasks > 0 || isMoved) && (
      <IncompleteTasksBox hasTasks={program?.incompleteTasks} {...otherProps}>
        <Typography variant='body2'>
          {
            isMoved && !program?.incompleteTasks
              ? (
                <>
                  Start <Hyperlink onClick={handlePostClick} component='span' ref={postUGC} role='link' data-href={ugcUrl}>posting</Hyperlink> what
                  you love about your new home!
                </>
              )
              : 'You have incomplete tasks that need attention'
          }
        </Typography>
      </IncompleteTasksBox>
    )
  );
}

export default IncompleteTasksCTA;
