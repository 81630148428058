import React, {
  forwardRef, useMemo, useRef,
} from 'react';
import imageUrlBuilder from '@sanity/image-url';
import { Chip } from '@tmap/mmm-style-guide/src/Chip';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';
import { Tooltip } from '@tmap/mmm-style-guide/src/Tooltip';
import { styled } from '@tmap/mmm-style-guide/src/styled';

import locationLabelText from '@tmap/mmm-core/locationLabelText';
import { toKey, types } from '@tmap/mmm-core/entityKey';

import LinkCard from '../linkCard';
import findImpressionFeature from '../../lib/findImpressionFeature';
import { getTagPills } from '../../lib/tags';
import { offerTypeFromValue } from '../../lib/filterTypes';
import getPrimaryIncentiveImage from '../../lib/getPrimaryIncentiveImage';
import useImpressions, { ImpressionTracker } from '../../hooks/useImpressions';
import client from '../../client';
import IncompleteTasksCTA from './incompleteTasksCTA';
import IncentiveCTAButton from './incentiveCTAButton';
import useActiveProgram from '../../hooks/useActiveProgram';

const builder = imageUrlBuilder(client);

const incentiveImgStyles = () => ({
  width: '100%',
  maxHeight: '550px',
  objectFit: 'cover',
  borderRadius: 'inherit',
});
const StyledIncentivePicture = styled('picture')(incentiveImgStyles);
const StyledIncentiveImg = styled('img')(incentiveImgStyles);

const MoreIcon = forwardRef(({ count, ...props }, ref) => (
  <Typography variant='body2' {...props} ref={ref}>
    +{count}
  </Typography>
));

function IncentiveCard(props) {
  const {
    incentive,
    shouldLazyLoad,
    lazyProps,
    placementId,
    pageId,
    ...buttonProps
  } = props;

  const incentiveUrl = `/get-paid/${encodeURIComponent(incentive.slug.current)}`;
  const primaryImage = getPrimaryIncentiveImage(incentive);
  const altText = `Get paid to live in 
    ${locationLabelText(incentive.locationText, incentive.regionText)}`;

  const [valueIcons, plusMore] = useMemo(() => {
    const valueTypes = incentive?.values?.map((value) => value.valueType) || [];
    const deduped = Array.from(new Set(valueTypes));
    const allIcons = deduped.map(offerTypeFromValue);
    const MAX_ICONS = 5;
    if (allIcons.length > MAX_ICONS) {
      return ([
        allIcons.slice(0, MAX_ICONS - 1),
        {
          title: allIcons.slice(MAX_ICONS - 1).map((icon) => icon.title).join(', '),
          count: allIcons.length - MAX_ICONS + 1,
        },
      ]);
    }
    return [allIcons, null];
  }, [incentive.values]);

  const impressionMeta = useMemo(() => ({ pageId }), [pageId]);
  const { trackView, trackClick } = useImpressions(
    toKey(types.INCENTIVE, incentive._id),
    placementId,
    impressionMeta,
  );

  const baseElement = useRef();
  const favoriteTarget = useRef();
  const applyTarget = useRef();

  const handleImpressionClick = (event) => {
    const elementsToTrack = new Map([
      [favoriteTarget.current, 'favorite'],
      [applyTarget.current, 'apply'],
      [baseElement.current, 'base'],
    ]);

    const featureName = findImpressionFeature(event, elementsToTrack);
    trackClick({ feature: featureName });
  };

  const mediumImageUrl = builder.image(primaryImage).format('webp').width(600).height(200)
    .url();
  const smallImageUrl = builder.image(primaryImage).format('webp').width(450).height(200)
    .url();

  const program = useActiveProgram(incentive);

  return (
    <ImpressionTracker
      trackView={trackView}
      onClickCapture={handleImpressionClick}
      ref={baseElement}
    >
      <LinkCard href={incentiveUrl} alt={altText} sx={{ height: '100%' }}>
        <LinkCard.ImageWrapper>
          <LinkCard.Overlays sx={{ padding: 2 }}>
            <LinkCard.PillBar>
              {incentive.tags && getTagPills(incentive.tags)
                .map(({ label, icon, iconColor }, i) => <LinkCard.Pill key={`inc-${incentive._id}-pill-${i}`} label={label} icon={icon} iconColor={iconColor} />)}
            </LinkCard.PillBar>
            <LinkCard.FavoriteButton entity={incentive} ref={favoriteTarget} twoToneHeart />
          </LinkCard.Overlays>
          <LinkCard.Image borderRadius='8px' dimTop shouldLazyLoad={shouldLazyLoad} lazyProps={lazyProps}>
            <StyledIncentivePicture>
              <source srcSet={mediumImageUrl} width={600} height={200} media='(min-width: 1000px), (min-width: 500px) and (max-width: 599px)' />
              <source srcSet={smallImageUrl} width={450} height={200} />
              <StyledIncentiveImg src={smallImageUrl} width={450} height={200} alt={altText} />
            </StyledIncentivePicture>
          </LinkCard.Image>
        </LinkCard.ImageWrapper>
        <LinkCard.Footer>
          <Grid container spacing={1.25} sx={{ padding: 1, height: 'calc(100% + 10px)' }}>
            <Grid item xs={12}>
              <Grid container columnSpacing={1}>
                <Grid item xs>
                  <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
                    {incentive.title || 'Total Value'}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ fontSize: '16px' }}>
                    {incentive.totalValue ? `$${new Intl.NumberFormat().format(incentive.totalValue)}+` : ''}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {!program?.incompleteTasks ? (
              <Grid item xs={12}>
                <Grid container alignItems='flex-start'>
                  <Grid item xs sx={{ marginTop: '-6px' }}>
                    <Grid container spacing={0.5}>
                      {incentive.communities?.length > 0 ? (
                        Array.from(new Set(incentive.communities.map((c) => c.regionText)))
                          .map((regionText, i) => (
                            <Grid item key={`incentive-${incentive._id}-community-${i}`}>
                              <Chip
                                variant='pill'
                                size='small'
                                label={regionText || 'pls add {..., communities[]->{regionText}}'}
                              />
                            </Grid>
                          ))
                      ) : (
                        <Grid item>
                          <Chip
                            variant='pill'
                            size='small'
                            label={incentive.regionText}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={1}>
                      {valueIcons.map(({ icon: ValueIcon, title }, i) => (
                        <Grid item key={`incentive-${incentive._id}-value-${i}`}>
                          <Tooltip title={title} aria-label={`incentive value: ${title}`}>
                            <ValueIcon fontSize='13px' />
                          </Tooltip>
                        </Grid>
                      ))}
                      {plusMore && (
                        <Grid item>
                          <Tooltip title={plusMore.title} aria-label={`more incentive values: ${plusMore.title}`}>
                            <MoreIcon fontSize='13px' count={plusMore.count} />
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <IncompleteTasksCTA incentive={incentive} />
              </Grid>
            )}
            <Grid item xs={12} sx={{ alignSelf: 'end' }}>
              <IncentiveCTAButton
                ref={applyTarget}
                incentive={incentive}
                {...buttonProps}
              />
            </Grid>
          </Grid>
        </LinkCard.Footer>
      </LinkCard>
    </ImpressionTracker>
  );
}

export default IncentiveCard;
