import React, { useCallback, useContext, useMemo } from 'react';
import { useRouter } from 'next/router';

import { Button as DefaultButton } from '@tmap/mmm-style-guide/src/Button';
import stopEvent from '@tmap/mmm-style-guide/src/util/stopEvent';
import { RocketLaunch } from '@tmap/mmm-style-guide/src/Icon';

import { LeadStatus } from '@tmap/mmm-core/leads/status';

import OfferProgressContext from './offerProgressContext';
import StartCommonAppLink from '../commonApp/startLink';
import useActiveProgram from '../../hooks/useActiveProgram';

const IncentiveCTAButton = React.forwardRef((props, ref) => {
  const {
    incentive,
    Button = DefaultButton,
    linkDetailsForNew = false,
    disableWhenInactive = false,
    handleClick = () => { },
    ...buttonProps
  } = props;

  const offerProgressSettings = useContext(OfferProgressContext) || {};
  if (Object.keys(offerProgressSettings).length === 0) {
    console.warn('offerProgressSettings required for accurate program card behavior');
  }
  const { steps } = offerProgressSettings;

  const activeProgram = useActiveProgram(incentive);
  const currentStep = useMemo(() => (
    activeProgram
      ? steps?.find((step) => step.statuses.includes(activeProgram?.status?.[0].status))
      : undefined
  ), [steps, activeProgram]);

  const router = useRouter();

  const statusUrl = `/myprofile/offers/${encodeURIComponent(activeProgram?.userListItemId)}`;
  const redirectToStatus = useCallback((e) => {
    stopEvent(e);
    handleClick();
    router.push(statusUrl);
  }, [router, statusUrl, handleClick]);

  const isNew = activeProgram?.status?.[0]?.status === LeadStatus.NEW;
  if ((!activeProgram || (isNew && !linkDetailsForNew)) && incentive.status === 'inactive' && disableWhenInactive) {
    return (
      <Button
        onClick={redirectToStatus}
        fullWidth
        variant='contained'
        color='primary'
        disableElevation
        disabled
        {...buttonProps}
      >
        Application Closed
      </Button>
    );
  }

  if (!activeProgram || (isNew && !linkDetailsForNew && !activeProgram.incompleteTasks)) {
    return (
      <StartCommonAppLink
        ref={ref}
        incentive={incentive}
        Button={Button}
        handleApply={handleClick}
        buttonProps={{
          variant: 'contained',
          color: 'primary',
          disableElevation: true,
          ...buttonProps,
        }}
      />
    );
  }

  const isMoved = [LeadStatus.MOVED, LeadStatus.INCENTIVE_DELIVERED]
    .includes(activeProgram?.status?.[0].status);
  let buttonText;
  if (isMoved) {
    buttonText = 'Next Steps';
  } else if (activeProgram.incompleteTasks) {
    buttonText = 'Get Started';
  } else {
    buttonText = currentStep?.cardButtonText || 'Track Progress';
  }

  return (
    <Button
      onClick={redirectToStatus}
      fullWidth
      variant='contained'
      color='primary'
      disableElevation
      ref={ref}
      role='link'
      data-href={statusUrl}
      startIcon={(activeProgram.incompleteTasks && !isMoved) ? <RocketLaunch /> : undefined}
      {...buttonProps}
    >
      {buttonText}
    </Button>
  );
});

export default IncentiveCTAButton;
