import React, { forwardRef } from 'react';
import { useRouter } from 'next/router';
import { UserListItemStatus } from '@tmap/mmm-core/commonApp/userListItemStatus';
import { toKey } from '@tmap/mmm-core/entityKey';
import { Button as DefaultButton } from '@tmap/mmm-style-guide/src/Button';
import stopEvent from '@tmap/mmm-style-guide/src/util/stopEvent';
import useUserListItem from '../../hooks/useUserListItem';
import useRequireAuth from '../../hooks/useRequireAuth';
import getApplicationStartURL from '../../lib/commonApp/getApplicationStartURL';
import isCommonAppIncentive from '../../lib/incentive/isCommonAppIncentive';

const StartCommonAppLink = forwardRef((props, ref) => {
  const {
    incentive,
    Button = DefaultButton,
    buttonProps,
    handleApply = () => { },
  } = props;
  const { requireAuthRedirect } = useRequireAuth();
  const router = useRouter();
  const incentiveKey = toKey('incentive', incentive._id);
  const userListItem = useUserListItem({ incentive });
  const cancelEvents = (e) => {
    stopEvent(e);
  };

  const redirectToApplication = () => {
    if (!incentiveKey) return;
    handleApply();
    requireAuthRedirect(getApplicationStartURL(userListItem, incentiveKey));
  };
  const redirectToOffer = () => {
    router.push(`/get-paid/${encodeURIComponent(incentive.slug.current)}`);
  };

  if (
    incentive.status !== 'inactive'
    && isCommonAppIncentive(incentive)
    && [ // has not already applied
      undefined,
      UserListItemStatus.REMOVED,
      UserListItemStatus.ACTIVE,
    ].includes(userListItem?.status)
  ) {
    return (
      // eslint-disable-next-line max-len
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div onClick={cancelEvents} ref={ref}>
        <Button onClick={redirectToApplication} variant='outlined' color='inverted' fullWidth {...buttonProps}>Apply Now</Button>
      </div>
    );
  }

  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={cancelEvents} ref={ref}>
      <Button onClick={redirectToOffer} variant='outlined' color='inverted' fullWidth {...buttonProps}>View Program</Button>
    </div>
  );
});

export default StartCommonAppLink;
